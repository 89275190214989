import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Backdrop,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import NavigationService from "../../../utils/NavigationService";

interface BlockedPopUpProps {
  backdrop?: boolean;
}

const BlockedPopUp: React.FC<BlockedPopUpProps> = ({ backdrop = true }) => {
  const { t } = useTranslation();

  return (
    <>
      {backdrop && (
        <Backdrop
          open
          style={{
            zIndex: 999,
            backgroundColor: "rgba(0, 0, 0, 0.01)",
            backdropFilter: "blur(8px)",
          }}
        />
      )}
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            width: "400px",
            padding: "24px",
            borderRadius: "12px",
            textAlign: "center",
            border: "1px solid var(--color-border)",
          },
        }}
        maxWidth="xs"
        open
        disableEscapeKeyDown
        style={{
          height: "fit-content",
          minHeight: "330px",
          width: "fit-content",
          top: "25%",
          left: "40%",
        }}
      >
        <DialogTitle
          style={{ fontWeight: 600, fontSize: "18px", color: "#101828" }}
        >
          {t("statistics.trialEnded.popupTitle")}
        </DialogTitle>
        <DialogContent
          style={{
            fontSize: "14px",
            color: "#475467",
            marginTop: "7px",
            overflowY: "initial",
          }}
        >
          {t("statistics.trialEnded.popupContent")}
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "12px",
            marginTop: "32px",
          }}
        >
          <Button
            variant="outlined"
            style={{ flexGrow: 1 }}
            onClick={() => {
              const salesMessage = t("statistics.trialEnded.salesMessage");
              window.Intercom("showNewMessage", salesMessage);
            }}
          >
            {t("statistics.trialEnded.contactSales")}
          </Button>
          <Button
            variant="contained"
            style={{ flexGrow: 1 }}
            onClick={() => NavigationService.navigateToBilling()}
          >
            {t("statistics.trialEnded.updateBilling")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BlockedPopUp;
