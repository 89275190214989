import React from "react";
import SignupForm from "./SignupForm";
import logo from "../../../images/XFA_woordmerk_gray900.svg";
import "../AuthStateApp.css";
import { Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import step1 from "../../../images/signup-step1.png";
import step2 from "../../../images/signup-step2.png";
import step3 from "../../../images/signup-step3.png";
import step4 from "../../../images/signup-step4.png";
import checkMark from "../../../images/checkmark-icon.png";
import { LanguageSelector } from "../../../theme/components/LanguageSelector";
import { Role } from "../../API/XFA_API";
import { Routes, Route } from "react-router-dom";
import NavigationService from "../../../utils/NavigationService";

interface SignupLayoutProps {
  currentStep: number;
  onNextStep: () => void;
  onPreviousStep: () => void;
  onSwitchToLogin: () => void;
  onRefreshRoles: () => void;
  onBillingSkip: () => void;
  onSignIn: (user: any) => void;
  fixedEmail: string | undefined;
  role: Role | undefined;
  newOrganzation: boolean;
}

const SignupLayout: React.FC<SignupLayoutProps> = ({
  currentStep,
  onNextStep,
  onPreviousStep,
  onSwitchToLogin,
  onSignIn,
  onRefreshRoles,
  onBillingSkip,
  fixedEmail,
  role,
  newOrganzation,
}) => {
  const { t } = useTranslation();
  return (
    <div id="signupBackground">
      <div id="darksidebar">
        <div id="signupSidebarContent">
          <div style={{ textAlign: "center" }}>
            <img alt="logo" src={logo} style={{ height: 50 }} />
          </div>
          <div className="stepsContainer">
            {!newOrganzation && (
              <>
                <SignupStep
                  image={currentStep > 0 ? checkMark : step1}
                  title={t("login.signup.step1Title")}
                  description={t("login.signup.step1Description")}
                  isActive={currentStep === 0}
                />
                <div
                  className={
                    currentStep > 0
                      ? "verticalDivider"
                      : "verticalDividerWithoutBorder"
                  }
                />
                <SignupStep
                  image={currentStep > 1 ? checkMark : step2}
                  title={t("login.signup.step2Title")}
                  description={t("login.signup.step2Description")}
                  isActive={currentStep === 1}
                />
                <div
                  className={
                    currentStep > 1
                      ? "verticalDivider"
                      : "verticalDividerWithoutBorder"
                  }
                />
              </>
            )}
            {!fixedEmail && (
              <>
                <SignupStep
                  image={currentStep > 2 ? checkMark : step3}
                  title={t("login.signup.step3Title")}
                  description={t("login.signup.step3Description")}
                  isActive={currentStep === 2}
                />
                <div
                  className={
                    currentStep > 2
                      ? "verticalDivider"
                      : "verticalDividerWithoutBorder"
                  }
                />
                <SignupStep
                  image={currentStep > 3 ? checkMark : step4}
                  title={t("login.signup.step4Title")}
                  description={t("login.signup.step4Description")}
                  isActive={currentStep === 3}
                />
              </>
            )}
          </div>
        </div>
        <div id="sidebarSignupFooter">
          {currentStep < 2 && (
            <Typography
              variant="formlinkdescription"
              style={{ display: "inline", paddingRight: 3 }}
            >
              {t("login.signinLinkDescription")}
            </Typography>
          )}
          <Link
            href="/login"
            style={{ textDecoration: "none" }}
            onClick={(event) => {
              event.preventDefault();
              onSwitchToLogin();
              NavigationService.navigateToLogin();
            }}
          >
            <Typography variant="formlink" style={{ display: "inline" }}>
              {currentStep < 2 ? t("login.signinLink") : t("login.signOut")}
            </Typography>
          </Link>
        </div>
      </div>
      <div id="signupContainer">
        <div id="signupContent">
          <Routes>
            <Route
              path="/signup/:username"
              element={
                <SignupForm
                  currentStep={currentStep}
                  onNextStep={onNextStep}
                  onPreviousStep={onPreviousStep}
                  onSwitchToLogin={onSwitchToLogin}
                  onRefreshRoles={onRefreshRoles}
                  onBillingSkip={onBillingSkip}
                  signUp={true}
                  onSignIn={onSignIn}
                  fixedEmail={fixedEmail}
                  role={role}
                />
              }
            />
            <Route
              path="/signup"
              element={
                <SignupForm
                  currentStep={currentStep}
                  onNextStep={onNextStep}
                  onPreviousStep={onPreviousStep}
                  onSwitchToLogin={onSwitchToLogin}
                  onRefreshRoles={onRefreshRoles}
                  onBillingSkip={onBillingSkip}
                  signUp={true}
                  onSignIn={onSignIn}
                  fixedEmail={fixedEmail}
                  role={role}
                />
              }
            />
          </Routes>
        </div>
        <div id="sidebarFooter">
          <LanguageSelector
            languageNames={{ en: "English", nl: "Nederlands" }}
          />
        </div>
      </div>
    </div>
  );
};

interface SignupStepProps {
  image: string;
  title: string;
  description: string;
  isActive: boolean;
}

const SignupStep: React.FC<SignupStepProps> = ({
  image,
  title,
  description,
  isActive,
}) => (
  <div className={`signupStep ${isActive ? "active" : ""}`}>
    <div className={`stepImage ${isActive ? "active" : ""}`}>
      <img src={image} />
    </div>
    <div className="stepDetails">
      <div className={`stepTitle ${isActive ? "active" : ""}`}>{title}</div>
      <div className="stepDescription">{description}</div>
    </div>
  </div>
);

export default SignupLayout;
