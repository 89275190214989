import React from "react";
import { Role } from "../../API/XFA_API";

export const handleDiscovery = async (
  startDiscoveryFn: (role: Role) => Promise<void>,
  roles: Role[] | undefined,
  dispatch: React.Dispatch<any>,
): Promise<void> => {
  if (
    !roles ||
    roles.length === 0 ||
    roles[0].organization.organization_id === undefined
  ) {
    return;
  }
  dispatch({ type: "SET_LOADING", payload: { discoveryLoading: true } });
  dispatch({ type: "SET_LOADING", payload: { urlLoading: true } });

  await startDiscoveryFn(roles[0]);
  dispatch({ type: "SET_SIGNUP_STEP", payload: 0 });
  dispatch({ type: "SET_AUTH_STATE", payload: "signedIn" });
};
