import { Auth } from "aws-amplify";
import XFA_API, { Role } from "../API/XFA_API";
import { SignedInUser } from "./Login/SignedInUser";
import { AuthAction, AuthState } from "./authReducer";
import { Dispatch } from "react";
import { Buffer } from "buffer";
import NavigationService from "../../utils/NavigationService";
import { isValidEmail } from "../../utils";

export const initializeApp = async (
  dispatch: Dispatch<AuthAction>,
  state: AuthState,
): Promise<void> => {
  dispatch({ type: "SET_LOADING", payload: { signInLoading: true } });
  const decode = (str: string): string =>
    Buffer.from(str, "base64").toString("binary");

  const pathname = window.location.pathname;
  const usernameFromPath = location.pathname.split("/")[2] || "";

  const urlParams = new URLSearchParams(window.location.search);
  const createNewOrganization = urlParams.get("newOrganization");

  dispatch({
    type: "SET_CREATE_NEW_ORGANIZATION",
    payload: createNewOrganization === "true",
  });

  const step = Number(urlParams.get("step"));
  dispatch({ type: "SET_SIGNUP_STEP", payload: step });

  try {
    await refreshToken(dispatch);

    const user = await Auth.currentAuthenticatedUser();
    dispatch({ type: "SET_COGNITO_USER", payload: user });

    if (
      usernameFromPath.length > 0 &&
      user.username !== decode(usernameFromPath) &&
      isValidEmail(decode(usernameFromPath))
    ) {
      dispatch({ type: "SET_FIXED_EMAIL", payload: decode(usernameFromPath) });
    }

    if (!user) {
      dispatch({ type: "SET_AUTH_STATE", payload: "login" });
      return;
    }

    const cognitoGroups =
      user.signInUserSession.idToken.payload["cognito:groups"];
    const hasRelevantGroup =
      cognitoGroups &&
      cognitoGroups.some(
        (group: string) =>
          group.includes("Apple") ||
          group.includes("Microsoft") ||
          group.includes("Google"),
      );

    const emailVerified =
      user.attributes?.email_verified ||
      user.signInUserSession?.idToken?.payload?.email_verified ||
      hasRelevantGroup ||
      false;

    dispatch({
      type: "SET_EMAIL_VERIFIED",
      payload: emailVerified,
    });

    if (user && !emailVerified) {
      localStorage.setItem("signUp", user.username);
      updateUrl(1, dispatch);
      return;
    }

    const signedInUser = getSignedInUser(user);
    dispatch({ type: "SET_SIGNED_IN_USER", payload: signedInUser });

    await refreshRoles(dispatch, state);
    if (
      user.username !== decode(usernameFromPath) &&
      isValidEmail(decode(usernameFromPath))
    ) {
      dispatch({ type: "SET_AUTH_STATE", payload: "signedIn" });
      const isSignup = pathname.startsWith("/signup");
      NavigationService.navigateToHome(
        usernameFromPath + "?isSignup=" + isSignup,
      );
      return;
    }
    await refreshPaymentMethod(dispatch, state);
    if (step < 4) {
      dispatch({ type: "SET_AUTH_STATE", payload: "signedIn" });
    }
  } catch (error) {
    console.error("Error initializing app:", error);
    if (usernameFromPath.length > 0 && isValidEmail(decode(usernameFromPath))) {
      dispatch({ type: "SET_FIXED_EMAIL", payload: decode(usernameFromPath) });
    }
    if (window.location.pathname.startsWith("/signup")) {
      dispatch({ type: "SET_AUTH_STATE", payload: "signup" });
    } else {
      dispatch({ type: "SET_AUTH_STATE", payload: "login" });
    }
  } finally {
    dispatch({ type: "SET_LOADING", payload: { signInLoading: false } });
  }
};

export const refreshRoles = async (
  dispatch: Dispatch<AuthAction>,
  state: AuthState,
): Promise<void> => {
  let localRoles: Role[] | undefined = [];

  try {
    dispatch({ type: "SET_LOADING", payload: { roleLoading: true } });
    localRoles = await XFA_API.getRoles();
    dispatch({ type: "SET_ROLES", payload: localRoles });
  } catch (error) {
    console.error("Error refreshing roles:", error);
  } finally {
    dispatch({ type: "SET_LOADING", payload: { roleLoading: false } });
    dispatch({ type: "SET_LOADING", payload: { urlLoading: true } });
    refreshSignUpStep(dispatch, { ...state, roles: localRoles });
  }
};

const refreshPaymentMethod = async (
  dispatch: Dispatch<AuthAction>,
  state: AuthState,
): Promise<void> => {
  try {
    dispatch({ type: "SET_LOADING", payload: { paymentLoading: true } });

    const roles = state.roles;

    const organization_id = roles?.find((role) => !role.blocked_access)
      ?.organization.organization_id;

    if (!organization_id) {
      return;
    }

    if (localStorage.getItem("skipPayment") === "true") {
      dispatch({ type: "SET_HAS_PAYMENT_METHOD", payload: true });
      dispatch({ type: "SET_AUTH_STATE", payload: "signedIn" });
      return;
    }

    const paymentMethod = await XFA_API.getPaymentMethod(organization_id);

    if (paymentMethod) {
      dispatch({ type: "SET_HAS_PAYMENT_METHOD", payload: true });
      dispatch({ type: "SET_AUTH_STATE", payload: "signedIn" });
    }
  } catch (error) {
    console.error("Error refreshing payment method:", error);
  } finally {
    dispatch({ type: "SET_LOADING", payload: { paymentLoading: false } });
  }
};

export const refreshToken = async (
  dispatch: Dispatch<AuthAction>,
): Promise<void> => {
  try {
    dispatch({ type: "SET_LOADING", payload: { tokenLoading: true } });

    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = cognitoUser.signInUserSession;

    cognitoUser.refreshSession(currentSession.refreshToken, (err: Error) => {
      console.error("Unable to refresh token:", err);
      dispatch({ type: "SET_LOADING", payload: { tokenLoading: false } });
    });
  } catch (e) {
    console.error("Unable to refresh token:", e);
    dispatch({ type: "SET_LOADING", payload: { tokenLoading: false } });
  }
};

export const refreshSignUpStep = (
  dispatch: Dispatch<AuthAction>,
  state: AuthState,
): void => {
  if (state.createNewOrganization) {
    dispatch({ type: "SET_AUTH_STATE", payload: "signup" });
    NavigationService.navigateToSignup(state.signupStep, true);
    dispatch({ type: "SET_LOADING", payload: { urlLoading: false } });
    return;
  }
  dispatch({ type: "SET_LOADING", payload: { urlLoading: true } });

  const activeRole = state.roles?.find((role: Role) => !role.blocked_access);

  const isNotLoading =
    !state.loading.roleLoading &&
    !state.loading.paymentLoading &&
    !state.loading.signInLoading &&
    !state.loading.tokenLoading &&
    state.authState !== "loading";

  if (!isNotLoading || state.signupStep === 4) {
    dispatch({ type: "SET_LOADING", payload: { urlLoading: false } });
    return;
  }

  const pathname = window.location.pathname;
  const stateSignedInUser = (state.signedInUser as SignedInUser) || null;

  //exclude admin from signup flow
  if (
    state.cognitoUser &&
    stateSignedInUser &&
    stateSignedInUser.isAdministrator()
  ) {
    dispatch({ type: "SET_LOADING", payload: { urlLoading: false } });
    dispatch({ type: "SET_AUTH_STATE", payload: "signedIn" });
    return;
  }

  if (state.signupStep > 4 && pathname.startsWith("/signup")) {
    updateUrl(state.signupStep, dispatch);
    dispatch({ type: "SET_LOADING", payload: { urlLoading: false } });
    return;
  }

  if (!state.cognitoUser && pathname.startsWith("/signup")) {
    const step = localStorage.getItem("signUp") === null ? 0 : 1;
    dispatch({ type: "SET_SIGNUP_STEP", payload: step });
    updateUrl(step, dispatch);
  } else if (
    state.cognitoUser &&
    (!state.roles || state.roles.length === 0) &&
    !state.emailVerified
  ) {
    dispatch({ type: "SET_SIGNUP_STEP", payload: 1 });
    updateUrl(1, dispatch);
  } else if (
    (state.signedInUser && state.signupStep === 0 && !state.roles) ||
    (state.emailVerified && !activeRole?.organization.organization_id)
  ) {
    dispatch({ type: "SET_SIGNUP_STEP", payload: 2 });
    updateUrl(2, dispatch);
  } else if (
    activeRole?.organization.organization_id &&
    (localStorage.getItem("skipPayment") !== "true" ||
      !state.hasPaymentMethod) &&
    pathname.startsWith("/signup")
  ) {
    dispatch({ type: "SET_SIGNUP_STEP", payload: 3 });
    updateUrl(3, dispatch);
  } else if (
    (localStorage.getItem("skipPayment") === "true" && state.signedInUser) ||
    (state.hasPaymentMethod && pathname.startsWith("/signup")) ||
    (state.signedInUser && state.roles && state.emailVerified)
  ) {
    dispatch({ type: "SET_AUTH_STATE", payload: "signedIn" });
    if (pathname.startsWith("/signup")) {
      NavigationService.navigateToHome();
    }
  }
  dispatch({ type: "SET_LOADING", payload: { urlLoading: false } });
};

export const getSignedInUser = (
  user: any | undefined | false,
): SignedInUser | null => {
  if (!user) return null;

  const session = user.getSignInUserSession();
  if (session) {
    const payload = session.getIdToken().decodePayload();
    const groups = payload["cognito:groups"] || [];
    return new SignedInUser(payload.sub, payload.email, groups);
  }

  throw new Error("Invalid cognito object");
};

const updateUrl = (step: number, dispatch: Dispatch<AuthAction>): void => {
  const urlParams = new URLSearchParams();

  if (step !== 0) {
    urlParams.set("step", step.toString());
  }
  dispatch({ type: "SET_AUTH_STATE", payload: "signup" });
  NavigationService.navigateToSignup(step);
};
