import React from "react";
import { AuthAction } from "../authReducer";

export const createAuthActions = (dispatch: React.Dispatch<AuthAction>) => {
  return {
    setSignupStep: (step: number) => {
      dispatch(setSignupStep(step));
    },

    setAuthState: (state: "loading" | "signedIn" | "signup" | "login") => {
      dispatch(setAuthState(state));
    },

    setLoading: (loadingKey: keyof AuthAction["payload"], value: boolean) => {
      dispatch(setLoading(loadingKey, value));
    },

    setMultipleLoadingStates: (
      loadingStates: Partial<AuthAction["payload"]>,
    ) => {
      dispatch(setMultipleLoadingStates(loadingStates));
    },

    setCognitoUser: (user: any | null) => {
      dispatch(setCognitoUser(user));
    },

    setSignedInUser: (user: any | null) => {
      dispatch(setSignedInUser(user));
    },

    setRoles: (roles: any | undefined) => {
      dispatch(setRoles(roles));
    },

    setFixedEmail: (email: string | undefined) => {
      dispatch(setFixedEmail(email));
    },

    setEmailVerified: (verified: boolean) => {
      dispatch(setEmailVerified(verified));
    },

    setHasPaymentMethod: (hasPaymentMethod: boolean) => {
      dispatch(setHasPaymentMethod(hasPaymentMethod));
    },

    setNewOrganzation: (newOrganization: boolean) => {
      dispatch(setNewOrganzation(newOrganization));
    },
  };
};

const setSignupStep = (step: number): AuthAction => ({
  type: "SET_SIGNUP_STEP",
  payload: step,
});

const setAuthState = (
  state: "loading" | "signedIn" | "signup" | "login",
): AuthAction => ({
  type: "SET_AUTH_STATE",
  payload: state,
});

const setLoading = (
  loadingKey: keyof AuthAction["payload"],
  value: boolean,
): AuthAction => ({
  type: "SET_LOADING",
  payload: { [loadingKey]: value },
});

const setMultipleLoadingStates = (
  loadingStates: Partial<AuthAction["payload"]>,
): AuthAction => ({
  type: "SET_LOADING",
  payload: loadingStates,
});

const setCognitoUser = (user: any | null): AuthAction => ({
  type: "SET_COGNITO_USER",
  payload: user,
});

const setSignedInUser = (user: any | null): AuthAction => ({
  type: "SET_SIGNED_IN_USER",
  payload: user,
});

const setRoles = (roles: any | undefined): AuthAction => ({
  type: "SET_ROLES",
  payload: roles,
});

const setFixedEmail = (email: string | undefined): AuthAction => ({
  type: "SET_FIXED_EMAIL",
  payload: email,
});

const setEmailVerified = (verified: boolean): AuthAction => ({
  type: "SET_EMAIL_VERIFIED",
  payload: verified,
});

const setHasPaymentMethod = (hasPaymentMethod: boolean): AuthAction => ({
  type: "SET_HAS_PAYMENT_METHOD",
  payload: hasPaymentMethod,
});

const setNewOrganzation = (newOrganization: boolean): AuthAction => ({
  type: "SET_CREATE_NEW_ORGANIZATION",
  payload: newOrganization,
});
