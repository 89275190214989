import React from "react";
import { useTranslation } from "react-i18next";
import {
  CircularProgress,
  Collapse,
  Divider,
  ListItemIcon,
} from "@mui/material";
import Typography from "@mui/material/Typography";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import UnfoldLess from "@mui/icons-material/UnfoldLess";
import UnfoldMore from "@mui/icons-material/UnfoldMore";

import AddIcon from "@mui/icons-material/Add";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";

import { Role } from "./API/XFA_API";

import { ClickAwayListener } from "@mui/base/ClickAwayListener";

import "./RoleSelector.css";
import NavigationService from "../utils/NavigationService";

interface RoleSelectorProps {
  activeRole: Role | undefined;
  inactiveRoles: Role[] | undefined;
  changeRole: (id: string) => void;
  refreshRoles: (id?: string) => void;
}

const RoleSelector: React.FC<RoleSelectorProps> = (
  props: RoleSelectorProps,
) => {
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    // When everything is loaded, and there are no roles, open create organization
    if (!props.activeRole && props.inactiveRoles?.length === 0) {
      NavigationService.navigateToExternal(
        "/signup?step=2&newOrganization=true",
      );
    }
  }, [props.activeRole, props.inactiveRoles]);

  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          setOpen(false);
        }}
      >
        <div
          className="rolePicker"
          style={{
            marginTop: 8,
            marginBottom: 8,
            contain: "content",
            borderRadius: 8,
            backgroundColor: open ? "var(--color-gray-200)" : undefined,
          }}
        >
          <ListItemButton
            onClick={() => {
              setOpen(!open);
            }}
            selected={open}
            style={{ height: 56, borderRadius: 0 }}
          >
            {props.activeRole ? (
              <>
                <ActiveRole role={props.activeRole} />
                <ListItemIcon style={{ margin: 0 }}>
                  {open ? (
                    <UnfoldLess style={{ fontSize: "large" }} />
                  ) : (
                    <UnfoldMore style={{ fontSize: "large" }} />
                  )}
                </ListItemIcon>
              </>
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress
                  style={{
                    width: 24,
                    height: 24,
                    color: "var(--color-gray-500)",
                  }}
                />
              </div>
            )}
          </ListItemButton>

          <Collapse in={open} timeout="auto" unmountOnExit>
            {props.inactiveRoles && props.inactiveRoles.length > 0 ? (
              props.inactiveRoles.map((role) => (
                <React.Fragment key={role.organization.organization_id}>
                  <Divider />
                  <ListItemButton
                    onClick={() => {
                      setOpen(false);
                      props.changeRole(role.organization.organization_id);
                    }}
                    style={{ height: 56, borderRadius: 0 }}
                  >
                    <InactiveRole role={role} />
                  </ListItemButton>
                </React.Fragment>
              ))
            ) : (
              <div style={{ textAlign: "center" }}>
                <Typography
                  style={{
                    paddingTop: 5,
                    paddingBottom: 5,
                    color: "var(--color-gray-500)",
                    fontWeight: 500,
                    fontSize: 12,
                  }}
                >
                  {t("roles.noOtherRoles")}
                </Typography>
              </div>
            )}
            <Divider />
            <ListItemButton
              onClick={() => {
                NavigationService.navigateToExternal(
                  "/signup?step=2&newOrganization=true",
                );
              }}
              style={{ borderRadius: 0 }}
            >
              <Typography
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "var(--color-gray-500)",
                  fontWeight: 500,
                  fontSize: 12,
                }}
              >
                <AddIcon style={{ height: 12, width: 12 }} />
                <CorporateFareIcon style={{ height: 12, width: 12 }} />
                <span style={{ marginLeft: 8 }}>
                  {t("roles.createOrganization")}
                </span>
              </Typography>
            </ListItemButton>
          </Collapse>
        </div>
      </ClickAwayListener>
    </>
  );
};

interface ActiveRoleProps {
  role: Role;
}

function ActiveRole(props: ActiveRoleProps) {
  return (
    <div style={{ flexDirection: "column", width: "100%" }}>
      <ListItemText
        className={"organizationName"}
        primary={props.role.organization.name}
        style={{
          width: "100%",
        }}
      />
      <div className="roleName">
        {props.role.roleType !== "" ? props.role.roleType : ""}
      </div>
    </div>
  );
}

interface InactiveRoleProps {
  role: Role;
}

function InactiveRole(props: InactiveRoleProps) {
  return (
    <div style={{ flexDirection: "column", width: "100%" }}>
      <ListItemText
        className={"organizationName"}
        primary={props.role.organization.name}
        style={{
          width: "100%",
        }}
      />
      <div className="roleName">
        {props.role.roleType !== "" ? props.role.roleType : ""}
      </div>
    </div>
  );
}

export default RoleSelector;
