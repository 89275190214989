import React from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  Typography,
  CircularProgress,
  Divider,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Alert,
  Box,
} from "@mui/material";
import "./Overview.css";
import { Policies, Role } from "../API/XFA_API";
import blurredBackground from "../../images/overlay_blur_trial.png";
import NavigationService from "../../utils/NavigationService";
import { useLocation, useParams } from "react-router-dom";
import { Buffer } from "buffer";
import { isDiscoveryApplication, isValidEmail } from "../../utils";
import TrialCTA from "../Overview/TrialCTA/TrialCTA";
import OverviewEmptyState from "./OverviewEmptyState";
import { useApplications } from "./hooks/useApplications";
import { useDevices } from "./hooks/useDevices";
import { useTaskManager } from "./hooks/useTaskManager";
import { usePolicies } from "./hooks/usePolicies";
import StageCard from "./StageCard/StageCard";
import { useBillingSettings } from "./hooks/useBillingSettings";
import useOrganization from "./hooks/userOrganization";
import i18next from "i18next";
import DiscoveryHighlights from "../Discovery/DiscoveryHighlights";
import Health from "../Statistics/Health";
import OperatingSystem from "../Statistics/OperatingSystems";
import Usage from "../Statistics/Usage";
import { getRecentDevices } from "../Users/UserUtils";
import DropdownMenu from "../General/Dropdown/DropdownMenu";
import BlockedPopUp from "../General/BlockedPopUp/BlockedPopUp";

interface StatisticsProps {
  role: Role;
  onSignOut: (fixedEmail?: string, signup?: boolean) => Promise<void>;
}

const Overview: React.FC<StatisticsProps> = ({ role, onSignOut }) => {
  const { t } = useTranslation();
  const isBlocked = role.blocked_access;

  const [showEmptyState, setShowEmptyState] = React.useState(
    JSON.parse(localStorage.getItem("showEmptyState") || "true"),
  );
  const [languageKey, setLanguageKey] = React.useState(0);

  React.useEffect(() => {
    const handleLanguageChanged = () => setLanguageKey((prev) => prev + 1);
    i18next.on("languageChanged", handleLanguageChanged);

    return () => {
      i18next.off("languageChanged", handleLanguageChanged);
    };
  }, []);

  const {
    policies,
    loading: policiesLoading,
    error: policiesError,
  } = usePolicies(role, isBlocked);
  const {
    settings,
    onTrial,
    loading: billingLoading,
  } = useBillingSettings(role.organization.organization_id, role.roleType);
  const {
    applications,
    dashboardApplicationId,
    loading: appLoading,
    error: appError,
  } = useApplications(role);
  const enforcementApplications = applications.filter(
    (app) => !isDiscoveryApplication(app),
  );
  const [selectedPolicy, setSelectedPolicy] = React.useState<Policies | null>(
    null,
  );
  const {
    devices,
    loading: devicesLoading,
    error: devicesError,
  } = useDevices(role, selectedPolicy?.policy_id);
  const hasDevices = (devices && devices.length > 0) ?? false;
  const discoveryDevices = devices?.filter((device) => device.discovered);

  const { tasks, loading: taskLoading } = useTaskManager({
    applications,
    hasDevices,
    dashboardApplicationId,
    policies,
    isBlocked,
    role,
  });

  const {
    loading: organizationLoading,
    error: organizationError,
    autoVerifyMail,
    autoRiskMail,
  } = useOrganization({
    organizationId: role.organization.organization_id,
    t: t,
  });
  const awarenessActive = autoVerifyMail || autoRiskMail;
  const discoveryApplications = applications.filter((app) =>
    isDiscoveryApplication(app),
  );
  const discoveryActive = discoveryApplications.length > 0;

  const [isPopupOpen, setIsPopupOpen] = React.useState(false);
  const params = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const username = React.useMemo(
    () =>
      params.username
        ? Buffer.from(params.username, "base64").toString("binary")
        : undefined,
    [params.username],
  );
  const enforcementActive = enforcementApplications.length > 1;
  const atLeastOneStageInactive =
    !awarenessActive || !enforcementActive || !discoveryActive;

  React.useEffect(() => {
    if (username && isValidEmail(username)) {
      setIsPopupOpen(true);
    }
  }, [username]);

  React.useEffect(() => {
    if (!policies || policies.length === 0) return;
    const defaultPolicy = policies.find(
      (policy) => policy.policy_id === role.organization.default_policy_id,
    );
    setSelectedPolicy(defaultPolicy ?? null);
  }, [policies]);

  React.useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1240 && showEmptyState) {
        handleDismiss();
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [showEmptyState]);

  const handleDismiss = React.useCallback(() => {
    setShowEmptyState(false);
    localStorage.setItem("showEmptyState", JSON.stringify(false));
  }, []);

  const renderError = (error: string | null) =>
    error && (
      <div style={{ marginBottom: 8, marginTop: 16 }}>
        <Alert severity="error">{error}</Alert>
      </div>
    );

  if (isBlocked) {
    return (
      <>
        <Typography variant="pagetitle">{t("navigation.overview")}</Typography>
        <Divider style={{ marginTop: 16 }} />
        <div style={{ textAlign: "center", marginTop: 50 }}>
          <img src={blurredBackground} alt="blurred overview page" />
        </div>
        <BlockedPopUp backdrop={false} />
      </>
    );
  }

  if (
    appLoading ||
    devicesLoading ||
    taskLoading ||
    policiesLoading ||
    billingLoading ||
    organizationLoading
  ) {
    return (
      <div>
        <Typography variant="pagetitle">{t("navigation.overview")}</Typography>
        <Divider style={{ marginTop: 16 }} />
        <div style={{ textAlign: "center", marginTop: 50 }}>
          <CircularProgress size={30} />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div
        style={{ display: "flex", justifyContent: "space-between" }}
        className="overview-header"
      >
        <Typography variant="pagetitle">{t("navigation.overview")}</Typography>
        <div style={{ display: "flex", gap: "8px" }}>
          {policies && policies.length > 0 && (
            <DropdownMenu
              mode="select"
              defaultSelected={selectedPolicy?.name ?? "Select policy"}
              actions={policies.map((policy) => ({
                label: (policy && policy.name) ?? "policy",
                icon: null,
                onClick: () => {
                  setSelectedPolicy(policy);
                },
              }))}
            />
          )}
          {!showEmptyState && (
            <Button
              variant="contained"
              onClick={() => {
                setShowEmptyState(true);
                localStorage.setItem("showEmptyState", JSON.stringify(true));
              }}
            >
              {t("statistics.emptyState.getStartedTitle")}
            </Button>
          )}
        </div>
      </div>
      <Divider style={{ marginTop: 16 }} />
      {renderError(appError)}
      {renderError(policiesError)}
      {renderError(devicesError)}
      {showEmptyState && (
        <OverviewEmptyState
          tasks={tasks}
          onDismiss={handleDismiss}
          dismissable={hasDevices}
        />
      )}

      <Box
        display="flex"
        marginTop="16px"
        gap={"8px"}
        height={atLeastOneStageInactive ? 224 : 188}
        flexWrap="wrap"
      >
        <Box flex="1 1 300px" key={`discovery-${languageKey}`}>
          <StageCard
            key={`discovery-${languageKey}`}
            title={t("navigation.discovery")}
            active={discoveryActive}
            description={t("overview.details.thisMonth")}
            actionText={
              !discoveryActive
                ? t("overview.actions.enableDiscovery")
                : undefined
            }
            actionTime={
              !discoveryActive ? t("overview.actions.setupTime5") : undefined
            }
            onActionClick={() =>
              NavigationService.navigateToDiscoveryOverview()
            }
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Typography variant="h4">
                {discoveryDevices?.length ?? 0}{" "}
              </Typography>
              <Typography
                variant="h6"
                style={{ marginTop: "auto", marginLeft: "4px" }}
              >
                {t("overview.details.discoveryDevices", {
                  count: discoveryDevices?.length ?? 0,
                })}
              </Typography>
            </div>
          </StageCard>
        </Box>
        <Box flex="1 1 300px" key={`awareness-${languageKey}`}>
          <StageCard
            key={`awareness-${languageKey}`}
            title={t("navigation.awareness")}
            active={awarenessActive}
            description=""
            actionText={
              !awarenessActive
                ? t("overview.actions.enableAwarenessEmails")
                : undefined
            }
            actionTime={
              !awarenessActive ? t("overview.actions.setupTime1") : undefined
            }
            onActionClick={() =>
              NavigationService.navigateToAwarenessOverview()
            }
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Typography
                variant="h6"
                style={{
                  marginTop: "auto",
                  color: autoVerifyMail
                    ? "var(--color-gray-900)"
                    : "var(--color-gray-400)",
                  fontSize: autoVerifyMail ? "20px" : "18px",
                }}
              >
                <Trans
                  i18nKey="overview.details.verificationMails"
                  values={{
                    status: autoVerifyMail
                      ? t("status.active").toLowerCase()
                      : t("status.inactive").toLowerCase(),
                  }}
                  components={{
                    bold: <strong style={{ fontWeight: "900" }} />,
                  }}
                />
              </Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Typography
                variant="h6"
                style={{
                  marginTop: "4px",
                  color: autoRiskMail
                    ? "var(--color-gray-900)"
                    : "var(--color-gray-400)",
                  fontSize: autoRiskMail ? "20px" : "18px",
                }}
              >
                <Trans
                  i18nKey="overview.details.riskMails"
                  values={{
                    status: autoRiskMail
                      ? t("status.active").toLowerCase()
                      : t("status.inactive").toLowerCase(),
                  }}
                  components={{
                    bold: <strong style={{ fontWeight: "900" }} />,
                  }}
                />
              </Typography>
            </div>
          </StageCard>
        </Box>
        <Box flex="1 1 300px" key={`applications-${languageKey}`}>
          <StageCard
            key={`applications-${languageKey}`}
            title={t("navigation.applications")}
            active={enforcementActive}
            description=""
            actionText={
              !enforcementActive
                ? t("overview.actions.startEnforcingXfa")
                : undefined
            }
            actionTime={
              !enforcementActive ? t("overview.actions.setupTime10") : undefined
            }
            onActionClick={() => NavigationService.navigateToApplications()}
          >
            <div
              style={{ display: "flex", flexDirection: "row" }}
              key={`applications-children-${languageKey}`}
            >
              <Typography variant="h4">
                {enforcementApplications.length - 1}{" "}
              </Typography>
              <Typography
                variant="h6"
                style={{ marginTop: "auto", marginLeft: "4px" }}
              >
                {t("overview.details.applicationsEnforced", {
                  count: enforcementApplications.length - 1,
                })}
              </Typography>
            </div>
          </StageCard>
        </Box>
      </Box>
      {onTrial && settings && <TrialCTA role={role} settings={settings} />}
      <div className="statistics">
        <div className="overview">
          {devices && devices?.length > 0 && (
            <>
              <Usage role={role} />
              <Health devices={devices} selectedPolicy={selectedPolicy} />
              <OperatingSystem devices={devices} />
            </>
          )}
        </div>
      </div>
      {policies &&
        policies.length > 0 &&
        devices &&
        devices.length > 0 &&
        selectedPolicy && (
          <DiscoveryHighlights
            role={role}
            devices={getRecentDevices(devices)}
            selectedPolicy={selectedPolicy}
          />
        )}
      <Dialog
        open={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        aria-labelledby="username-popup-title"
      >
        <DialogTitle
          id="username-popup-title"
          style={{ fontWeight: 600, fontSize: "18px", color: "#101828" }}
        >
          <Trans
            i18nKey="statistics.invitation.popupTitle"
            values={{ email: username }}
            components={{ strong: <strong /> }}
          />
        </DialogTitle>
        <DialogContent>
          <Typography>
            <Trans
              i18nKey="statistics.invitation.popupContent"
              values={{ email: role.email }}
              components={{ strong: <strong /> }}
            />
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsPopupOpen(false)}>
            {t("statistics.invitation.cancel")}
          </Button>
          <Button
            onClick={async () => {
              const isSignup = searchParams.get("isSignup") === "true";
              await onSignOut(username, isSignup);
              const route = isSignup ? "/signup/" : "/signin/";
              NavigationService.navigateTo(route + params.username);
              setIsPopupOpen(false);
            }}
          >
            {t("statistics.invitation.logout")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Overview;
