import React from "react";
import { AuthAction } from "../authReducer";
import { getSignedInUser, refreshRoles } from "../authActions";
import { Auth } from "aws-amplify";
import { deleteDeviceToken } from "xfa-connect-sdk-js";
import NavigationService from "../../../utils/NavigationService";
import { createAuthActions } from "../hooks/useAuthActions";

export const handleSignInAction = async (
  user: any,
  dispatch: React.Dispatch<AuthAction>,
  state: any,
): Promise<void> => {
  if (!user) {
    return;
  }

  const authActions = createAuthActions(dispatch);

  authActions.setCognitoUser(user);
  authActions.setSignedInUser(getSignedInUser(user));

  localStorage.setItem("signUp", user.username);

  const emailVerified =
    user.attributes?.email_verified ||
    user.signInUserSession?.idToken?.payload?.email_verified ||
    false;
  authActions.setEmailVerified(emailVerified);

  await refreshRoles(dispatch, state);
};

export const handleSignOutAction = async (
  dispatch: React.Dispatch<AuthAction>,
  fixedEmail?: string,
  signup?: boolean,
) => {
  await Auth.signOut();
  const authActions = createAuthActions(dispatch);
  authActions.setCognitoUser(undefined);
  authActions.setSignedInUser(undefined);
  authActions.setAuthState(signup && signup === true ? "signup" : "login");
  authActions.setRoles(undefined);

  localStorage.clear();
  deleteDeviceToken();
  if (fixedEmail) {
    authActions.setFixedEmail(fixedEmail);
  }
};

export const handleSwitchToLoginAction = async (
  dispatch: React.Dispatch<AuthAction>,
): Promise<void> => {
  const authActions = createAuthActions(dispatch);

  await Auth.signOut();
  authActions.setCognitoUser(undefined);
  authActions.setSignedInUser(undefined);
  authActions.setAuthState("login");
  localStorage.removeItem("signUp");
};

export const handlePreviousStepAction = async (
  dispatch: React.Dispatch<AuthAction>,
  state: any,
): Promise<void> => {
  const authActions = createAuthActions(dispatch);

  if (state.signupStep === 1) {
    await Auth.signOut();
    authActions.setCognitoUser(undefined);
    authActions.setSignedInUser(undefined);
  }

  authActions.setSignupStep(Math.max(0, state.signupStep - 1));
};

export const handleNextStepAction = async (
  dispatch: React.Dispatch<AuthAction>,
  state: any,
): Promise<void> => {
  const authActions = createAuthActions(dispatch);
  if (state.signupStep > 1) {
    await refreshRoles(dispatch, state);
  }
  authActions.setSignupStep(state.signupStep + 1);
};

export const handleOtherIdentificationMethodAction = (
  dispatch: React.Dispatch<AuthAction>,
  state: any,
): void => {
  const authActions = createAuthActions(dispatch);

  NavigationService.navigateToNewApplication();
  authActions.setNewOrganzation(false);
  authActions.setAuthState("signedIn");
  authActions.setSignupStep(0);
  refreshRoles(dispatch, state);
};

export const handleNoSetupAction = (
  dispatch: React.Dispatch<AuthAction>,
  state: any,
): void => {
  const authActions = createAuthActions(dispatch);

  NavigationService.navigateToExternal("/");
  authActions.setNewOrganzation(false);
  authActions.setAuthState("signedIn");
  authActions.setSignupStep(0);

  refreshRoles(dispatch, state);
};
