// UserUtils.ts

import { useTranslation } from "react-i18next";
import { Device, Policies } from "../API/XFA_API";
import { getDeviceCheck, DeviceCheck } from "./CheckUtils";

export enum UserRisk {
  Safe = "safe",
  Unsafe = "unsafe",
  Unknown = "unknown",
}

export enum DeviceRisk {
  Unsupported = "unsupported", //Unsupported is when the device is not supported by XFA
  Safe = "safe", //Safe is when all security checks of the policy are met (regardless of verified status)
  Unsafe = "unsafe", //Unsafe is when at least one security check of the policy is not met
  Unknown = "unknown", //Unknown is when not all checks required in policy are known about the device
  NotPresent = "not_present", //NotPresent is when (potential) risk is not present on device
}

export enum DeviceStatus {
  Verified = "verified", //Verified is when the device is verified by XFA
  Unverified = "unverified", //Unverified is when the device information comes from a source other than XFA
}

/**
 * Filters devices that have been submitted within the last specified number of days.
 *
 * @param devices - Array of device objects.
 * @param daysAgo - Number of days to look back from today. Default is 30 days.
 * @returns Filtered array of recent devices.
 */
export function getRecentDevices(devices: Array<Device>, daysAgo: number = 30) {
  const now = new Date();
  const pastDate = new Date(now);
  pastDate.setDate(now.getDate() - daysAgo);

  return devices.filter((device) => {
    const submittedDate = new Date(device.timestamp_submitted * 1000); // Multiply with 1000 because it doesn't include the milliseconds
    return submittedDate >= pastDate;
  });
}

export function getSupported(device: Device): boolean {
  return (
    ["Windows", "macOS", "Android", "iOS", "iPadOS"].includes(
      device.os_name ?? "",
    ) && !device.unsupported
  );
}

export function hasRiskChecks(device: Device, riskType: DeviceRisk): boolean {
  for (const check of Object.keys(checkRequirements) as DeviceCheck[]) {
    const { applicableDeviceTypes } = checkRequirements[check];

    // Determine if the check is applicable based on device type (desktop or mobile)
    const isApplicable =
      !applicableDeviceTypes ||
      (applicableDeviceTypes.includes("desktop") &&
        isDesktop(device.os_name)) ||
      (applicableDeviceTypes.includes("mobile") && isMobile(device.os_name));

    if (!isApplicable) continue;

    // Get the risk for this particular check
    const risk = getDeviceCheck(device, check);

    // Return true if the risk matches the specified risk type
    if (risk === riskType) {
      return true;
    }
  }
  return false;
}

const checkRequirements: {
  [key in DeviceCheck]: {
    policyKey?: keyof Policies;
    applicableDeviceTypes?: ("desktop" | "mobile")[];
  };
} = {
  os_uptodate: { policyKey: "os" },
  os_autoupdate: {
    policyKey: "os_autoupdate",
    applicableDeviceTypes: ["desktop"],
  },
  diskencryption_active: { policyKey: "disk_encryption" },
  authentication_active: { policyKey: "screen_lock" },
  antivirus_enabled: {
    policyKey: "antivirus",
    applicableDeviceTypes: ["desktop"],
  },
  chrome_uptodate: { policyKey: "browser", applicableDeviceTypes: ["desktop"] },
  firefox_uptodate: {
    policyKey: "browser",
    applicableDeviceTypes: ["desktop"],
  },
  edge_uptodate: { policyKey: "browser", applicableDeviceTypes: ["desktop"] },
  safari_uptodate: { policyKey: "browser", applicableDeviceTypes: ["desktop"] },
  passwordmanager: {
    policyKey: "password_manager",
    applicableDeviceTypes: ["desktop"],
  },
  biometrics: { applicableDeviceTypes: ["mobile"] },
  nativeClientInstalled: {
    policyKey: "installed",
    applicableDeviceTypes: ["desktop"],
  },
};

/**
 * Get device risk
 *
 * @param device - Device objects.
 * @param policy
 * @returns DeviceStatus containing the device risk.
 */
export function getDeviceRisk(
  device: Device,
  policy?: Policies | undefined | null,
): DeviceRisk {
  if (!getSupported(device)) {
    return DeviceRisk.Unsupported;
  }

  if (!policy) {
    if (hasRiskChecks(device, DeviceRisk.Unsafe)) {
      return DeviceRisk.Unsafe;
    }
    if (hasRiskChecks(device, DeviceRisk.Unknown)) {
      return DeviceRisk.Unknown;
    }
    return DeviceRisk.Safe;
  }

  let hasUnsafe = false;
  let hasUnknown = false;

  for (const check of Object.keys(checkRequirements) as DeviceCheck[]) {
    const { policyKey, applicableDeviceTypes } = checkRequirements[check];

    const isApplicable =
      !applicableDeviceTypes ||
      (applicableDeviceTypes.includes("desktop") &&
        isDesktop(device.os_name)) ||
      (applicableDeviceTypes.includes("mobile") && isMobile(device.os_name));

    if (!isApplicable) continue;

    const requiresCheck = policyKey ? isDefined(policy[policyKey]) : false;

    if (!requiresCheck) continue;

    const risk = getDeviceCheck(device, check);

    if (risk === DeviceRisk.Unsafe) {
      hasUnsafe = true;
    } else if (risk === DeviceRisk.Unknown) {
      hasUnknown = true;
    }
  }

  if (hasUnsafe) {
    return DeviceRisk.Unsafe;
  } else if (hasUnknown) {
    return DeviceRisk.Unknown;
  }

  return DeviceRisk.Safe;
}

/**
 * Get device status
 *
 * @param device - Device objects.
 * @returns DeviceStatus containing the device status.
 */

export function getDeviceStatus(device: Device): DeviceStatus {
  if (
    device.skip === true ||
    device.discovered === true ||
    device.unsupported === true ||
    !getSupported(device)
  ) {
    return DeviceStatus.Unverified;
  }
  return DeviceStatus.Verified;
}

export const getUserSecurityStatus = (
  activeDevices: Device[],
  policy: Policies | undefined,
): DeviceRisk => {
  let hasUnsafe = false;
  let hasUnknown = false;
  let allSafe = true;

  if (activeDevices.length === 0) {
    return DeviceRisk.Unknown;
  }

  activeDevices.forEach((device) => {
    const status = getDeviceRisk(device, policy);
    if (status === DeviceRisk.Unsafe) {
      hasUnsafe = true;
      allSafe = false;
    } else if (status === DeviceRisk.Unknown) {
      hasUnknown = true;
      allSafe = false;
    }
  });

  if (hasUnsafe) {
    return DeviceRisk.Unsafe;
  } else if (hasUnknown) {
    return DeviceRisk.Unknown;
  } else if (allSafe) {
    return DeviceRisk.Safe;
  } else {
    return DeviceRisk.Unknown;
  }
};

export const getDeviceType = (
  device: Device,
  t: (key: string) => string,
): string => {
  const osName = device.os_name ?? "";
  const deviceModel = device.model ?? "";

  if (!osName) return t("devices.unknownDevice");

  const osNameLower = osName.toLowerCase();
  const deviceModelLower = deviceModel.toLowerCase();

  if (osNameLower.includes("ios") && !deviceModelLower.includes("ipad")) {
    // Devices discovered in Microsoft will be ios regardless of iPhone/iPad
    if (
      device.discovered &&
      device.microsoft_device_ids &&
      !device.google_mobile_device_ids &&
      !device.google_cloud_identity_device_ids
    ) {
      return "iPhone / iPad";
    }

    return "iPhone";
  }
  if (osNameLower.includes("ipad") || deviceModelLower.includes("ipad")) {
    return "iPad";
  }
  if (osNameLower.includes("mac")) {
    return "Mac";
  }
  if (osNameLower.includes("windows")) {
    return "Windows PC";
  }
  if (osNameLower.includes("android")) {
    return "Android Device";
  }
  if (osNameLower.includes("linux")) {
    return "Linux PC";
  }

  return osName; // Default to showing the os_name if no match is found
};

export const isDesktop = (osName: string | undefined) => {
  if (!osName) {
    return false;
  }
  return ["Windows", "macOS", "Linux"].includes(osName);
};

export const isMobile = (osName: string | undefined) => {
  if (!osName) {
    return false;
  }
  return ["iOS", "iPadOS", "Android"].includes(osName);
};

export const isDefined = (value: any): boolean => {
  return value !== null && value !== undefined && value !== "";
};

export const getRelativeTime = (timestamp: number) => {
  const { t } = useTranslation();
  const now = new Date().getTime();
  const secondsPast = (now - timestamp) / 1000;

  if (secondsPast < 60) {
    return t("time.justNow");
  }
  if (secondsPast < 3600) {
    return `${Math.floor(secondsPast / 60)} ${t("time.minutesAgo")}`;
  }
  if (secondsPast <= 86400) {
    return `${Math.floor(secondsPast / 3600)} ${t("time.hoursAgo")}`;
  }
  if (secondsPast <= 2592000) {
    return `${Math.floor(secondsPast / 86400)} ${t("time.daysAgo")}`;
  }
  if (secondsPast <= 18144000) {
    return `${Math.floor(secondsPast / 604800)} ${t("time.weeksAgo")}`;
  }
  if (secondsPast <= 31104000) {
    return `${Math.floor(secondsPast / 2592000)} ${t("time.monthsAgo")}`;
  }
  return `${Math.floor(secondsPast / 31104000)} ${t("time.yearsAgo")}`;
};
