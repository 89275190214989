import React, { useEffect } from "react";
import { initializeApp, refreshSignUpStep } from "../authActions";
import { AuthState, AuthAction } from "../authReducer";

export const useAuthStateHandlers = (
  state: AuthState,
  dispatch: React.Dispatch<AuthAction>,
): void => {
  useEffect(() => {
    initializeApp(dispatch, state);
  }, []);

  useEffect(() => {
    refreshSignUpStep(dispatch, state);
  }, [
    dispatch,
    state.loading.paymentLoading,
    state.loading.roleLoading,
    state.loading.tokenLoading,
    state.loading.signInLoading,
    state.signupStep,
  ]);
};
