import React, { useReducer } from "react";
import { CircularProgress } from "@mui/material";
import { initialState, reducer, AuthState, AuthAction } from "./authReducer";
import { refreshRoles } from "./authActions";
import Home from "../Home";
import SignupLayout from "./SignUp/SignUpLayout";
import LoginLayout from "./Login/LoginLayout";
import "./AuthStateApp.css";
import DiscoveryOnboarding from "../DiscoveryOnboarding/DiscoveryOnboarding";
import {
  startGoogleDiscovery,
  startMicrosoftDiscovery,
  startOktaDiscovery,
} from "../../utils/DiscoveryService";
import {
  handleNextStepAction,
  handleNoSetupAction,
  handleOtherIdentificationMethodAction,
  handlePreviousStepAction,
  handleSignInAction,
  handleSignOutAction,
  handleSwitchToLoginAction,
} from "./utils/authActions";
import { handleDiscovery } from "./hooks/discoveryHandlers";
import { useAuthStateHandlers } from "./hooks/useAuthStateHandlers";
import { isLoadingState } from "./utils/loadingUtils";
import NavigationService from "../../utils/NavigationService";

const AuthStateHandler: React.FC = () => {
  const [state, dispatch] = useReducer<React.Reducer<AuthState, AuthAction>>(
    reducer,
    initialState,
  );
  useAuthStateHandlers(state, dispatch);

  const onGoogleDiscovery = () =>
    handleDiscovery(startGoogleDiscovery, state.roles, dispatch);
  const onMicrosoftDiscovery = () =>
    handleDiscovery(startMicrosoftDiscovery, state.roles, dispatch);
  const onOktaDiscovery = () =>
    handleDiscovery(startOktaDiscovery, state.roles, dispatch);

  const handleRefreshRoles = async () => {
    await refreshRoles(dispatch, state);
  };

  const isLoading = isLoadingState(state.loading);

  if (isLoading) {
    return (
      <div className="loading-container">
        <CircularProgress />
      </div>
    );
  }

  const renderComponent = () => {
    if (state.signupStep === 5) {
      return (
        <DiscoveryOnboarding
          onGoogleDiscovery={onGoogleDiscovery}
          onMicrosoftDiscovery={onMicrosoftDiscovery}
          onOktaDiscovery={onOktaDiscovery}
          onNoSetup={() => {
            handleNoSetupAction(dispatch, state);
          }}
          onOtherIdentificationMethod={() => {
            handleOtherIdentificationMethodAction(dispatch, state);
          }}
        />
      );
    }

    if (state.authState === "signedIn" && state.roles) {
      return (
        <Home
          user={state.signedInUser!}
          onSignOut={async (fixedEmail, signup) => {
            await handleSignOutAction(dispatch, fixedEmail, signup);
          }}
        />
      );
    }

    if (
      state.authState === "signup" ||
      (state.signedInUser && !state.roles && state.authState !== "login") ||
      (state.fixedEmail !== undefined && state.authState !== "login") ||
      state.signupStep === 4
    ) {
      return (
        <SignupLayout
          currentStep={state.signupStep}
          onNextStep={() => {
            handleNextStepAction(dispatch, state);
          }}
          onPreviousStep={() => {
            handlePreviousStepAction(dispatch, state);
          }}
          onSwitchToLogin={() => {
            handleSwitchToLoginAction(dispatch);
          }}
          onRefreshRoles={handleRefreshRoles}
          onBillingSkip={() =>
            dispatch({ type: "SET_SIGNUP_STEP", payload: 5 })
          }
          onSignIn={async (user: any) => {
            await handleSignInAction(user, dispatch, state);
          }}
          fixedEmail={state.fixedEmail}
          role={state.roles?.find((role) => !role.blocked_access)}
          newOrganzation={state.createNewOrganization}
        />
      );
    }

    if (state.authState === "login") {
      return (
        <LoginLayout
          onSignIn={async (user: any) => {
            await handleSignInAction(user, dispatch, state);
          }}
          onSwitchToSignup={() =>
            dispatch({ type: "SET_AUTH_STATE", payload: "signup" })
          }
          cognitoUser={state.cognitoUser}
          setCognitoUser={(user: any) =>
            dispatch({ type: "SET_COGNITO_USER", payload: user })
          }
        />
      );
    }

    return <CircularProgress />;
  };

  return <>{renderComponent()}</>;
};

export default AuthStateHandler;
